<template>
    <el-menu
        v-model:default-active="activeMenu"
        :mode="mode"
        @select="goto"
        :collapse="isCollapse"
        :unique-opened="true"
        :router="false"
        background-color="transparent"
        class="main-navigation-menu"
        :class="{ 'nav-collapsed': isCollapse }"
    >
      <el-menu-item-group v-for="item in navigationItems" :title="item.groupTitle || ''">
        <el-menu-item
          v-for="navItem in item.items"
          :index="navItem.path"
          :disabled="navItem.disabled || false"
        >
          <router-link :to="{ path: navItem.path }" v-slot="{ href, navigate }">
            <el-link
              :underline="false"
              :class="{'theme-primary-color': activeMenu === navItem.path}"
              @click="$router.push({ path: navItem.path })"
            >
              <i :class="[`mdi`, navItem.icon ]"></i>
              <span>{{ navItem.navTitle }}</span>
            </el-link>
          </router-link>
        </el-menu-item>
      </el-menu-item-group>
<!--        <el-sub-menu index="/dashboards" popper-class="main-navigation-submenu">-->
<!--            <template #title> <i class="mdi mdi-gauge"></i><span>Dashboard</span> </template>-->
<!--            <el-menu-item index="/dashboard">-->
<!--                <span slot="title">Analytical</span>-->
<!--            </el-menu-item>-->
<!--            <el-menu-item index="/ecommerce-dashboard">-->
<!--                <span slot="title">eCommerce</span>-->
<!--            </el-menu-item>-->
<!--            <el-menu-item index="/crypto-dashboard">-->
<!--                <span slot="title">Crypto</span>-->
<!--            </el-menu-item>-->
<!--        </el-sub-menu>-->
<!--        <el-menu-item index="/calendar">-->
<!--            <i class="mdi mdi-calendar"></i><span slot="title">Calendar</span>-->
<!--        </el-menu-item>-->
    </el-menu>
</template>

<script lang="ts">
import { defineComponent } from "@vue/runtime-core"
import { detect } from "detect-browser"
import { useRoute } from "vue-router"
import { navigation } from "@super-admin-app/src/navigation"
const browser = detect()

// @ts-ignore
export default defineComponent({
    name: "Nav",
    props: ["mode", "isCollapse", "navigationItems"],
    data() {
        return {
            isIe: true,
            isEdge: true,
            activeLink: null as unknown as string
        }
    },
  computed: {
    activeMenu() {
      return this.getActiveMenuPath(this.$route)
    },
    route() {
      return this.$route
    }
  },
    methods: {
        goto(index: string) {
            if (index.charAt(0) === "/") {
                this.$router.push(index)
                this.$emit("push-page", { page: index })
            }
        },
        setLink(route: string) {
          return this.getActiveMenuPath(route)
        },
        getActiveMenuPath(currentRoute: any) {
          const allItems = navigation.flatMap(group => group.items);

          const isPathParent = allItems.find(i => {
            return i.path === currentRoute.path
          })

          if (isPathParent) {
            return currentRoute.path
          }

          const matchedItems = allItems.filter(item =>
            Array.isArray(item.relatedPaths) &&
            item.relatedPaths.includes(currentRoute.name)
          );

          if (matchedItems.length > 1) {
            console.warn(
              'Найдено несколько совпадений в relatedPaths. Возвращается первое совпадение:',
              matchedItems
            );
          }

          return matchedItems.length > 0 ? matchedItems[0].path : null;
        }
    },
    created() {
        if (browser && browser.name !== "ie") this.isIe = false
        if (browser && browser.name !== "edge") this.isEdge = false

        const route = useRoute()
        this.setLink(route.path)
        this.$router.afterEach((to, from) => {
            this.setLink(route.path)
        })
    }
})
</script>

<style lang="scss" scoped>
@import "@shared/assets/scss/_variables";

.el-menu {
    border: none;
}
.el-menu::before,
.el-menu::after {
    display: none;
}
.el-sub-menu,
.el-menu-item {
    .mdi {
        vertical-align: middle;
        margin-right: 5px;
        display: inline-block;
        width: 24px;
        text-align: center;
        font-size: 18px;
    }
}

.collapse-menu-btn {
    button {
        border-radius: 4px;
        border: none;
        color: $text-color-accent;
        background: lighten($text-color-accent, 30);
        font-weight: 600;
        padding: 4px 8px;
        margin-top: 20px;
        font-size: 12px;
        margin-left: 20px;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-align: center;

        i {
            font-size: 14px;
            margin-right: 5px;
        }
    }

    &.collapsed {
        button {
            margin-left: 17px;

            i {
                margin-right: -2px;
            }
        }
    }
}
</style>

<style lang="scss">
@import "@shared/assets/scss/_variables.scss";
.main-navigation-menu {
    transition: width 0.5s;

    &:not(.el-menu--collapse) {
        .el-sub-menu__title,
        .el-menu-item {
            height: 40px;
            line-height: 40px;
            background-color: transparent !important;
        }

        &:not(.el-menu--horizontal) {
            .el-menu-item,
            .el-sub-menu {
                position: relative;

                &::before {
                    content: "";
                    display: block;
                    width: 0px;
                    height: 1px;
                    position: absolute;
                    bottom: 10px;
                    left: 30px;
                    background: $text-color-primary;
                    z-index: 1;
                    opacity: 0;
                    transition: all 0.7s cubic-bezier(0.55, 0, 0.1, 1);
                }
                &:hover {
                    &::before {
                        width: 100px;
                        opacity: 1;
                        //left: 50px;
                        transform: translate(20px, 0);
                    }
                }

                &.is-active {
                    &::before {
                        background: $text-color-primary !important;
                    }
                }
            }
        }

        .el-sub-menu.is-opened {
            //background: #edf1f6 !important;
            //background: rgba(223, 228, 234, 0.38) !important;
            position: relative;

            &::after {
                content: "";
                display: block;
                width: 2px;
                position: absolute;
                top: 40px;
                bottom: 10px;
                left: 31px;
                background: $text-color-primary;
                z-index: 1;
            }

            &::before {
                display: none;
            }

            .el-menu-item,
            .el-sub-menu {
                &::before,
                &::after {
                    display: none;
                }
            }
        }

        .el-menu-item-group__title {
            padding: 15px 0 0px 20px;
            color: transparentize($text-color-primary, 0.65);
        }
    }

    .el-sub-menu__title,
    .el-menu-item:not(.is-active) {
        color: $text-color-primary;

        i {
            color: $text-color-primary;
        }
    }

    &.el-menu--collapse {
        .el-menu-item-group__title {
            padding: 15px 0 0px 0px;
            width: 100%;
            text-align: center;
        }

        .el-sub-menu__title:hover,
        .el-menu-item:hover {
            background-color: rgba(0, 0, 0, 0.05) !important;
        }
    }

    &.el-menu--horizontal {
        white-space: nowrap;
        /*width: fit-content;
		width: max-content;*/
        overflow: hidden;
        display: table;

        & > * {
            float: inherit !important;
            display: inline-block;
        }
    }

    &.nav-collapsed {
        .el-menu-item,
        .el-sub-menu__title {
            & > span {
                display: none;
            }
        }
    }
}

.main-navigation-submenu {
    .el-menu {
        background: #fff !important;

        .el-menu-item:not(.is-active) {
            color: $text-color-primary;
        }
        .el-menu-item:hover {
            background-color: transparentize($background-color, 0.3) !important;
        }
    }
}
</style>
