export const navigation = [
  {
    groupTitle: 'Admins',
    items: [
      {
        icon: 'mdi-account-wrench',
        navTitle: 'Admins List',
        path: '/admins-list'
      }
    ]
  },
  {
    groupTitle: 'Hosts',
    items: [
      {
        icon: 'mdi-account-star',
        navTitle: 'Hosts List',
        path: '/hosts-list',
        relatedPaths: ['host-page'],
      }
    ]
  },
  {
    groupTitle: 'Events',
    items: [
      {
        icon: 'mdi-account-group',
        navTitle: 'Events List',
        path: '/events-list',
        relatedPaths: ['event-page', 'repeat-event-page', 'create-event-page'],
      },
      {
        icon: 'mdi-shape-plus',
        navTitle: 'Categories List',
        path: '/events-categories-list'
      }
    ]
  },
  {
    groupTitle: 'Businesses',
    items: [
      {
        icon: 'mdi-account-tie',
        navTitle: 'Businesses List',
        path: '/businesses-list',
        relatedPaths: ['business-page']
      },
      {
        icon: 'mdi-advertisements',
        navTitle: 'Ads List',
        path: '/ads-list',
        relatedPaths: ['create-ad-page', 'ad-page', 'repeat-ad-page']
      }
    ]
  },
  {
    groupTitle: 'Attendees',
    items: [
      {
        icon: 'mdi-face-agent',
        navTitle: 'Attendees list',
        path: '/attendees-list'
      }
    ]
  },
  {
    groupTitle: 'Accounting',
    items: [
      {
        icon: 'mdi-cash-multiple',
        navTitle: 'Transactions',
        path: '/transactions',
      }
    ]
  },
  {
    groupTitle: 'Settings',
    items: [
      {
        icon: 'mdi-cog',
        navTitle: 'Settings',
        path: '/settings'
      },
    ]
  }
]
