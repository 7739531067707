import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_link = _resolveComponent("el-link")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_menu_item_group = _resolveComponent("el-menu-item-group")!
  const _component_el_menu = _resolveComponent("el-menu")!

  return (_openBlock(), _createBlock(_component_el_menu, {
    "default-active": _ctx.activeMenu,
    "onUpdate:defaultActive": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeMenu) = $event)),
    mode: _ctx.mode,
    onSelect: _ctx.goto,
    collapse: _ctx.isCollapse,
    "unique-opened": true,
    router: false,
    "background-color": "transparent",
    class: _normalizeClass(["main-navigation-menu", { 'nav-collapsed': _ctx.isCollapse }])
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navigationItems, (item) => {
        return (_openBlock(), _createBlock(_component_el_menu_item_group, {
          title: item.groupTitle || ''
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.items, (navItem) => {
              return (_openBlock(), _createBlock(_component_el_menu_item, {
                index: navItem.path,
                disabled: navItem.disabled || false
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_router_link, {
                    to: { path: navItem.path }
                  }, {
                    default: _withCtx(({ href, navigate }) => [
                      _createVNode(_component_el_link, {
                        underline: false,
                        class: _normalizeClass({'theme-primary-color': _ctx.activeMenu === navItem.path}),
                        onClick: ($event: any) => (_ctx.$router.push({ path: navItem.path }))
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("i", {
                            class: _normalizeClass([`mdi`, navItem.icon ])
                          }, null, 2),
                          _createElementVNode("span", null, _toDisplayString(navItem.navTitle), 1)
                        ]),
                        _: 2
                      }, 1032, ["class", "onClick"])
                    ]),
                    _: 2
                  }, 1032, ["to"])
                ]),
                _: 2
              }, 1032, ["index", "disabled"]))
            }), 256))
          ]),
          _: 2
        }, 1032, ["title"]))
      }), 256))
    ]),
    _: 1
  }, 8, ["default-active", "mode", "onSelect", "collapse", "class"]))
}